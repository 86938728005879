<template>
  
  <div class="col-md-7 col-sm-7 mt-10 boxmain">


       <div class="container boxrouting">
            <div class="row"  v-if="business==null">


              <div class="col-sm-12 col-xs-12 col-ms-12">
                <h2 class="titleh2n">
                  {{$t('business.home_title')}}
                  
                </h2>
              </div>
            
              <div class="col-sm-12 col-xs-12 col-ms-12 item-container col-md-6 block-1" v-for="k in 4" :key="k" >
                <div class="Mask" data-route="" >
                    <SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
                  <div class="titleh4">
                    <div class="account_tron">
                      {{$t('business.logo')}}
                    </div>

                    <div class="titleh44">
                      <h4>
                        <Skeleton/>
                      </h4>
                      <div class="details" data-route="">
                        <i class="fas fa-money-bill-wave"></i>
                        <span class="item-price" data-route=""> <Skeleton/></span>
                      </div>
                    </div>
                  </div>
                  <div class="texts" data-route="">
                    <p class="title" data-route=""> <Skeleton/></p>
                    <div class="details-container" data-route="">
                      <div class="details" data-route="">
                        <i class="far fa-clock"></i>
                         <Skeleton/>
                      </div>
                      <div class="details" data-route="">
                        <i class="fas fa-map-marker-alt"></i>
                         <Skeleton/>
                      </div>
                      <div class="details" data-route="">
                        <i class="fas fa-bus"></i>
                        <Skeleton/>
                      </div>
                    </div>

                  </div>
                    </SkeletonTheme>
                </div>
              </div>

           
             
            </div>

            <div class="row"  v-else >


              <div class="col-sm-12 col-xs-12 col-ms-12">
                <h2 class="titleh2n">
                  {{$t('business.home_title')}}
                  <a :href="$router.resolve({name:'business',params:{locale:$i18n.locale}}).href" :title="$t('business.home_see')">
                    {{$t('business.home_see')}}
                  </a>
                </h2>
              </div>


              <div class="col-sm-12 col-xs-12 col-ms-12 item-container col-md-6 block-1" v-for="(b,k) in paginationslice(business, 8, page)" :key="k">
                <div class="Mask" data-route="">
                  <div class="titleh4">

                    <div class="account_tron" v-if="b.logo==null">
                    
                      <img style="width:100%" src="/img/logo-4.e2674e91.png" alt="LOGO" />

                    </div>
                    
                    
                    <div class="account_tron" v-else>
                        <img :src="b.logo" alt="LOGO" />
                    </div>

                    <div class="titleh44">
                      <h4 style="width: 220px;">
                        {{b.tenDoanhNghiep}}
                      </h4>
                      <div class="details" data-route="">
                        <i class="fas fa-money-bill-wave"></i>
                        <span class="item-price" data-route="" > {{ parseInt(b.giaVe) | toCurrency}} </span>
                      </div>
                    </div>
                  </div>
                  <div class="texts" data-route="">
                    <p class="title" data-route=""> {{b.tuyenDuong}}</p>
                    <div class="details-container" data-route="" >
                      <div class="details" data-route="">
                        <i class="far fa-clock"></i>
                       {{b.dungBanVe}}h
                      </div>
                      <div class="details" data-route="">
                        <i class="fas fa-map-marker-alt"></i>
                        {{b.khoangCach}}km
                      </div>
                      <div class="details" data-route="">
                        <i class="fas fa-bus"></i>
                       {{b.soGhe}} {{$t('business.place')}}
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div class="col-sm-12 col-xs-12 col-ms-12">
                    <nav class="pagination-nav pdt-30" v-if="business!=null">
                  

                    <VuePagination
                    :page-count="Math.floor(business.length / 8)"
                    :page-range="3"
                    :margin-pages="2"
                    :click-handler="changePage"
                    :prev-text="'<'"
                    :next-text="'>'"
                    :container-class="'pagination-list text-center'"
                    :page-class="'page-numbers'"
                  >
                  </VuePagination>
                </nav>
              </div>
            
             
            </div>
          </div>

         

         <TopNews :Header="1"></TopNews>

          <!-- Ablums -->
          <div class="container boxtintuc">
            <div class="row">
              <div class="col-sm-12 col-xs-12 col-ms-12">
                <h2 class="titleh2n">
                  {{$t('question.album')}}
                  <a   
                      :href="$router.resolve({name: 'album',params:{locale:$i18n.locale}}).href"  
                       :title="$t('question.album')">
                    {{$t('news.see')}}
                  </a>
                </h2>

              </div>
            </div>
          </div>
          <div class="div-content pdb-10 div-content-image" v-if="albums==null">
            <div class="container">
              <div class="row">

                <div class="col-md-6 col-lg-6 col-xl-6 div-content-image-li" v-for="(a,ka) in 4" :key="ka">
                  <div class="project-item mrb-30">
                    <SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
                      <a class="project-thumb" href="" :title="a">
                         <Skeleton></Skeleton>
                        <div class="project-details p-3">
                          <h3 class="project-title">
                             <Skeleton></Skeleton>
                          </h3>
                        </div>
                      </a>
                    </SkeletonTheme>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="div-content pdb-10 div-content-image" v-else >
            <div class="container">
              <div class="row">

                <div class="col-md-6 col-lg-6 col-xl-6 div-content-image-li" v-for="(ab,k_ab) in albums" :key="k_ab">
                  <div class="project-item mrb-30">
                    <a class="project-thumb"
                      :href="$router.resolve({name: 'albumdetail',params:{locale:$i18n.locale,slug:ab.slug}}).href"  
                      :title="ab.name">
                      <img class="img-full lazy" :alt="ab.name" :src="myurl+''+ab.picture">
                      <div class="project-details p-3">
                        <h3 class="project-title">
                            {{ab.name}}
                        </h3>
                      </div>
                    </a>
                  </div>
                </div>
               

              </div>
            </div>
          </div>
           <!-- End Ablums -->



          <!-- Question -->
          <div class="container boxtintuc">
            <div class="row">
              <div class="col-sm-12 col-xs-12 col-ms-12">
                <h2 class="titleh2n">
                  {{$t("question.name_question")}}

                  <a   
                      :href="$router.resolve({name: 'question',params:{locale:$i18n.locale}}).href"  
                     :title="$t('question.name_question')">
                    {{$t('news.see')}}
                  </a>
                </h2>

              </div>
            </div>
          </div>


          <div class="request-a-call-back pdb-md-30" v-if="question==null">
            <div class="container">
              <div class="row">
                <div class="col-md-12 col-lg-12 col-xl-12 mrb-md-60">
                  <div class="faq-block">
                    <div class="accordion">

                      <SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
                      <div class="accordion-item">
                        <div class="accordion-header active">
                          <h5 class="title">{{$t('question.question')}} : <Skeleton></Skeleton></h5>
                          <span class="fa fa-plus"></span>
                        </div>
                        <div class="accordion-body">
                          <p>{{$t('question.answer')}}:
                           <Skeleton></Skeleton>
                          </p>
                        </div>
                      </div>
                      </SkeletonTheme>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="request-a-call-back pdb-md-30" v-else>
            <div class="container">
              <div class="row">
                <div class="col-md-12 col-lg-12 col-xl-12 mrb-md-60">
                  <div class="faq-block">
                    <div class="accordion">

                      <div class="accordion-item" v-for="(q,k) in question" :key="k">
                        <div class="accordion-header " >
                          <h5 class="title" v-html="$t('question.question')+': '+sript_tag(q.name)" >

                             
                          </h5>
                          <span class="fa fa-plus"></span>
                        </div>
                        <div class="accordion-body">
                          <p v-html="$t('question.answer')+': '+sript_tag(q.answer)">
                          
                          </p>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

           <!-- END Question -->


          <div class="container boxtintuc">
            <div class="row">
              <div class="col-sm-12 col-xs-12 col-ms-12">
                <h2 class="titleh2n">

                  {{$t('general.supplier_list')}}
                </h2>

              </div>
            </div>
          </div>
          <div class="container" v-if="supplier==null">
             <div class="owl-carouse client-items">
              <div class="client-item" v-for="(s1,k1) in 6" :key="k1">
                <SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
                    <Skeleton></Skeleton>
                </SkeletonTheme>
              </div>
              
             </div>
          </div>
          <div class="container" v-else >
             <div class="owl-carousel client-items">
              <div class="client-item" v-for="(s,k) in supplier" :key="k">
                <a :href="s.links" :title="s.name" target="_blank">
                  <img class="lazy" :src="myurl+''+s.picture" :alt="s.name">
                </a>
              </div>
             
             </div>
          </div>

  </div>


  <!-- Clients div End -->


</template>

<script>
import { Skeleton, SkeletonTheme } from "vue-loading-skeleton";
import VuePagination from "vuejs-paginate";
  import axios from 'axios';
  import TopNews from "@/components/default/TopNews.vue";


export default {
  
 
  data() {
    return {
     
      myurl:window.my_url,
      
      my_data:null,

      page:1,
      supplier:null,
      question:null,
      albums:null,
      business:null
    };
  },

  components: {
    Skeleton,
    SkeletonTheme,
    TopNews,
    VuePagination
    
  },
  created(){
        

          axios.get(window.my_api+"api/album/get-top-albums?language="+this.$i18n.locale,
                 {
                                 headers: {
                                   language: this.$i18n.locale
                                 }
                  })
          .then( (res)=>{           
            if(res.status==200){
                this.albums=res.data.data;//.filter(n => n.language==this.$i18n.locale);
                
                
            }
          });


          axios.get(window.my_api+"api/supplier/get-suppliers?language="+this.$i18n.locale,
                 {
                                 headers: {
                                   language: this.$i18n.locale
                                 }
                  })
          .then( (res)=>{
            
            if(res.status==200){
                this.supplier=res.data.data;//.filter(n => n.language==this.$i18n.locale);
                
            }
             

          });


          axios.get(window.my_api+"api/question/get-home-questions",
                 {
                                 headers: {
                                   language: this.$i18n.locale
                                 }
                  })
          .then( (res)=>{           
            if(res.status==200){
                this.question=res.data.data.slice(0,6);//.filter(n => n.language==this.$i18n.locale);
               
                
            }
          });

            axios.get(window.my_api+"api/tripassigment/get-tripassigments",
                 {
                                 headers: {
                                   language: this.$i18n.locale
                                 }
                  }
              )
          .then( (res)=>{       

            if(res.status==200){
             
                this.business=res.data.data.sort( (a,b) =>   parseInt( b.dungBanVe.substring(0,2)-parseInt(a.dungBanVe.substring(0,2) )))
                

                
            }
          });

          

          
          
  },
  filters: {
    strippedContent: function(string) {
           string= string.toString().replace(/<\/?[^>]+>/ig, " ").toString(); 


           return string;
    },
    toCurrency:function (value){
        if (typeof value !== "number") {
          return value;
        }
        var formatter = new Intl.NumberFormat('en-US', {
           
            maximumSignificantDigits:3
        });
        return formatter.format(value);
    }
  },
  methods:{
    sript_tag(string){
         string= string.replace(/<\/?[^>]+>/ig, " ").toString(); 

           return string;
    },

    paginationslice(data, pagesize, page) {
      return data.filter((value, n) => {
        return n >= pagesize * (page - 1) && n < pagesize * page;
      });
    },
    changePage(e) {
      this.page = e;
    }
  }

}
</script>
